import { initializeApp } from 'firebase/app'
import { getAuth, getIdToken } from 'firebase/auth'
import { ref, getDatabase } from 'firebase/database'
import { initializeFirestore, collection, onSnapshot, doc, getDoc, setDoc, addDoc, deleteDoc, CACHE_SIZE_UNLIMITED, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore'
import { getPreferences } from '@/modules/storage'
import { guid } from '@/modules/utils'

import configDev from '@/firebase/config-dev'
import configStage from '@/firebase/config-stage'
import configProd from '@/firebase/config-prod'

let config
switch (window.location.hostname) {
  case 'feature1.wisk.dev':
  case 'feature2.wisk.dev':
  case 'web.wisk.dev':
    config = configDev
    break
  case 'web.wisk.cool':
    config = configStage
    break
  default:
    config = configProd
}

if (getPreferences('environment') !== null) {
  switch (getPreferences('environment')) {
    case 1:
      config = configStage
      break
    case 2:
      config = configDev
      break

    default:
      config = configProd
      break
  }
}

const firebaseApp = initializeApp(config)
export const db = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true, localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager(), cacheSizeBytes: CACHE_SIZE_UNLIMITED }) })
export const AUTH = getAuth(firebaseApp)

const realTimeDatabase = getDatabase()
export const onlineCheckReference = ref(realTimeDatabase, '.info/connected')

let initialTracker = {},
  maxCountForOneByOneUpdate = 10,
  handlersMap = {},
  debug = false

export const getFirebaseUserToken = () =>
  new Promise((resolve, reject) => {
    try {
      if (AUTH.currentUser) {
        const expTime = AUTH.currentUser.stsTokenManager.expirationTime,
          currentTime = new Date().getTime()
        getIdToken(AUTH.currentUser, expTime - currentTime < 5 * 60000).then(token => {
          resolve(token)
        })
      } else {
        resolve(null)
      }
    } catch (error) {
      reject(error)
    }
  })


export const subscribeToCollection = ({ path, handler, key = guid() }) => {
  handlersMap[key] = handler

  let unsubscribe = onSnapshot(
    collection(db, path),
    snapshot => {
      if (debug) {
        console.log('subscribeToCollection change event', path, 'count: ', snapshot.docs.length)
      }

      let data = snapshot.empty ? [] : snapshot.docs.filter(d => d.exists).map(d => d.data())

      if (handlersMap[key]) {
        handlersMap[key](data || [])
      }
    },
    er => {
      console.log('er', er.message, er.code, path)
      if (er.code === 'permission-denied') {
        window.location.href = '/logout'
      }
    }
  )
  return unsubscribe
}

export const subscribeToCollectionOnlyChanges = ({ path, handler, key = guid() }) => {
  handlersMap[key] = handler

  let unsubscribe = onSnapshot(
    collection(db, path),
    snapshot => {
      let initial = !initialTracker[key],
        result = {
          set: [],
          remove: [],
          initial
        }
      if (debug) {
        console.log('initial', initial, 'key', key, 'snapshot.docChanges().length', snapshot.docChanges().length, 'maxCountForOneByOneUpdate', maxCountForOneByOneUpdate)
        console.log('subscribeToCollectionOnlyChanges change event', path, 'count: ', snapshot.docs.length, key)
      }
      if (initial || snapshot.docChanges().length >= maxCountForOneByOneUpdate) {
        let data = snapshot.empty ? [] : snapshot.docs.filter(d => d.exists).map(d => d.data())

        result.set = data
        result.initial = true
      } else {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added' || change.type === 'modified') {
            result.set.push(change.doc.data())
          }
          if (change.type === 'removed') {
            result.remove.push(change.doc.data())
          }
        })
      }

      initialTracker[key] = true

      if (handlersMap[key]) {
        handlersMap[key](result || [])
      }
    },
    er => {
      console.log('er', er.message, er.code)
      if (er.code === 'permission-denied') {
        window.location.href = '/logout'
      }
    }
  )

  return unsubscribe
}

export const subscribeToDocument = (path, handler) =>
  onSnapshot(
    doc(db, path),
    snapshot => {
      if (debug) {
        console.log('subscribeToDocument change event', path)
      }

      let data = snapshot.empty ? null : (snapshot.exists && snapshot.data()) || null

      handler(data)
    },
    er => {
      console.log('er', er.message, er.code)
      handler(null, er)
      if (er.code === 'permission-denied') {
        postMessage('refreshLogin')
      }
    }
  )

export const getFirestoreDocument = async (path, id) => {
  const docRef = doc(db, path, id)
  return (await getDoc(docRef)).data()
}

export const addDocument = (path, document) => {
  if (path && document) {
    document = JSON.parse(JSON.stringify(document))
    addDoc(collection(db, path), document)
  }
}

export const setDocument = (path, document, options = {}) => {
  if (path && document) {
    document = JSON.parse(JSON.stringify(document))
    setDoc(doc(db, path), document, options)
  }
}

export const setToDocument = ({ venueId, collectionName, document, idKey = 'id', merge = false }) => {
  if (venueId && document && typeof document === 'object' && document[idKey] && (typeof document[idKey] === 'string' || typeof document[idKey] === 'number')) {
    setDocument(`settings/web/byVenue/${venueId}/${collectionName}/${document[idKey]}`, document, { merge })
  }

  return null
}

export const deleteDocument = path => {
  if (path) {
    deleteDoc(doc(db, path))
  }
}
