<template>
  <b-row noGutters class="scanned-invoice-line-row" :class="[completionStatusClass, { expanded: localExpanded, collapsed: !localExpanded }]" :key="key" :id="`draft-invoice-line-${lineItem.id}`" @mouseenter="locateLine">
    <b-col>
      <b-container v-if="localExpanded" fluid class="pt-0 position-relative h-100" :id="domIdExpandedContainer">
        <b-row class="position-relative pt-1">
          <b-col cols="1" style="max-width: 45px;">
            <wiskInput inputType="checkbox" :modelValue="selected" @update:modelValue="$emit('toggleBatchState', $event)" class="chk-draft-invoice-line-select"
              :class="{ forceVisible: selected }" inputClass="bg-white" style="margin-left: -5px;" />

            <b-dropdown left menuClass="mt- " :disabled="localDisabled" size="sm" variant="link" toggle-class="text-decoration-none pt-0 d-block" class="ms-1 bt-draft-invoice-line-more"
              no-caret>
              <template v-slot:button-content>
                <icon name="wisk-more-info" :scale="0.9" class="text-secondary" />
              </template>

              <b-dropdown-item @click="insertLine('before')">
                {{ translations.txtScannedInvoicesLineInsertAbove }}
              </b-dropdown-item>
              <b-dropdown-item @click="insertLine('after')">
                {{ translations.txtScannedInvoicesLineInsertBelow }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <b-col cols="2">
            <b-button key="4" v-if="!localDisabled" size="sm" class="drag-sort-scanned-invoice-line" variant="link" v-tooltip="translations.txtGenericDragSort">
              <icon style="color: #869fac;" class="" name="wisk-rearrange"></icon>
            </b-button>
            <b-button key="99" v-if="locateLineOCRCellIds" size="sm" class="locate-line-scanned-invoice-line" variant="link" @click="locateLine" v-tooltip="translations.txtScannedInvoicesLocateLine">
              <icon style="color: #869fac;" class="" name="wisk-show"></icon>
            </b-button>
          </b-col>
          <b-col cols="3">
            <reviewMessages :disabled="localDisabled || !currentPermissionsByType.invoice_needs_review_add" :key="reviewMessagesKey"
              :lineItem="localLineItem" :containerDomId="domIdExpandedContainer" @operations="onChangeSingle"/>
          </b-col>
          <b-col cols="5">
            <small class="d-block text-dark">
              {{ translations.txtScannedInvoicesImportedTitle }}: {{ localLineItem.original_title }}
              <b-button key="5" v-if="!localLineItem.item_distributor_id && !localDisabled" size="sm" class="bt-copy-title m-0 p-0" v-tooltip="translations.txtCartBuilderPrefill" variant="link"
                @click="onChangeSingle({ type: 'title', value: localLineItem.original_title, from: lineItem.title }, 'titlePrefill')">
                <icon style="color: #869fac;" class="" :scale="0.6" name="wisk-clone"></icon>
              </b-button>
            </small>
          </b-col>
          <b-col cols="1">
            <b-button key="3" v-if="!localDisabled" size="sm" class="bt-delete-scanned-invoice-line" variant="link" v-tooltip="translations.txtGenericRemove"
              @click="removeLine" preventDoubleClick>
              <icon class="text-danger" :scale="0.9" name="wisk-trash" />
            </b-button>
          </b-col>
        </b-row>

        <confirm ref="attentionCommentDialog" textarea title="Review message" />

        <scannedInvoiceItem @selectedItem="onItemSelect" :lineItem="lineItem" v-if="lineItem" expanded :disabled="localDisabled" />

        <b-row noGutters :gap="0.5" class="flex-column flex-md-row flex-md-nowrap px-2 px-md-0 mt-2 mt-md-0" :style="{ '--inner-items': 4 + (entryType === 'manual' ? 2 : 1) }">
          <div class="invoice-flexible-line" :style="{ '--inner-item-size': 1 }">
            <wiskSelect infoTooltipKey="262d012c-32a9-4b10-855d-adb8f7b79bf4" :items="measurementTypes" ref="measurementTypeSelect"
              @change="onMeasurementTypeChange($event.id)" :disabled="localDisabled" required v-model="entryType" :label="translations.txtGenericMeasurement" triggerInputOnLoad />
          </div>
          <div class="invoice-flexible-line" :style="{ '--inner-item-size': entryType === 'manual' ? 2 : 1 }">
            <inventoryEntryInput @update:modelValue="setTotalPreviewOperation({ type: 'measurement', value: { ...$event, type: entryType, case_size: localLineItem.case_size }, from: lineItem.measurement }, 'inventoryEntryInput')"
              :modelValue="localLineItem.measurement" :item="selectedItem" horizontal :disabled="localDisabled" :inputType="entryType" class="px-0" v-if="entryType" emitFast required noGutters
              operation="measurement" @operation="onMeasurementOperationChange({ ...$event, ...{ value: { ...$event.value, type: entryType, case_size: localLineItem.case_size } }, from: lineItem.measurement })" />
          </div>
          <div class="invoice-flexible-line" :style="{ '--inner-item-size': 3 }">
            <priceEditor :disabled="localDisabled" :item="selectedItem" operation="price" @operation="setOperation" @update:modelValue="setTotalPreviewOperation({ type: 'price', value: $event })"
              :caseSize="selectedItem?.case_size || 1" :modelValue="localLineItem.price" discountAvailable inline horizontal :key="entryType" showPricePerUnit
              showPreviousPrices :previousPrice="localLineItem.previous_cost_per_unit" :currentPrice="localLineItem.price_per_unit" :movementId="draftInvoice?.movement_id" />
          </div>
        </b-row>
        <b-row class="mt-1">
          <b-col md="6" cols="12" class="pe-0">
            <div class="d-flex align-items-center px-0 me-auto position-relative">
              <wiskSelect noGutters size="xs" class="me-2 mb-1 flex-grow-1 flex-shrink-0" :modelValue="localLineItem.gl_account_id" @operation="setOperation($event, 'gl_account_id component @operation')"
                :disabled="localDisabled" :label="translations.txtGLAccount" :addNewItem="addGLAccountConfig" v-if="currentPermissionsByType.gl_account_assign && venue.accounting_enabled"
                operation="gl_account_id" operationEmpty="gl_account_id_clear" infoTooltipKey="a88258f4-0df4-4c3c-8fdc-0cf42c044e5a" :items="activeGLAccounts" />

              <wiskSelect noGutters size="xs" class="me-2 mb-1 flex-grow-1 flex-shrink-0" infoTooltipKey="1dc0973b-e5f8-48da-94bc-6c438c3b086c"
                :items="activeTaxes" @operation="setOperation($event, 'tax_rate_id component @operation')" :label="translations.txtTax" :addNewItem="addTaxConfig"
                :disabled="localDisabled || taxSelectorDisabled" v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled"
                :modelValue="localLineItem.tax_rate_id" operation="tax_rate_id" operationEmpty="tax_rate_id_clear" />

              <wiskSelect noGutters size="xs" class="me-2 mb-1 flex-grow-1 flex-shrink-0" infoTooltipKey="ca158e31-eeda-4d2f-adc9-bd13f9ca6b42" :items="accountingVenueClasses"
                @operation="setOperation($event, 'accounting_venue_class_id component @operation')" :label="translations.txtMovementAccountingQuickbooksClass"
                v-if="venue?.accounting_integration?.is_using_classes && venue.accounting_integration?.type === 'quickbooks'" style="z-index: 1;"
                :modelValue="localLineItem.accounting_venue_class_id" operation="accounting_venue_class_id" operationEmpty="accounting_venue_class_id_clear" />
            </div>
          </b-col>
          <b-col md="4" cols="12" class="text-end">
            <totalPreview v-if="!dataError" :expanded="expanded" :operations="[...totalPreviewOperations, ...operations]" :lineId="localLineItem.id" :scannedInvoiceId="scannedInvoiceId"
              :defaultValue="localLineItem" @update="localLineItem.price_per_unit = $event" />
            <small v-if="dataError" class="text-danger"> {{ dataError }} </small>
          </b-col>
          <b-col cols="1" class="px-0 text-end">
            <draftInvoiceLineTimeline :draftInvoiceId="scannedInvoiceId" :draftInvoiceLineId="localLineItem.id" />
          </b-col>
          <b-col md="1" cols="12" class="text-end px-0">
            <b-button v-if="!localDisabled" :disabled="!operations.length" variant="primary" size="sm" @click="save" class="me-1 px-2 py-1" style="margin-top: -10px;">
              {{ translations.txtGenericSave }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-if="!localExpanded" @click="$emit('select', localLineItem.id)" fluid class="pt-1 cursor-pointer position-relative h-100">
        <b-row noGutters>
          <b-col cols="1" style="max-width: 45px;">
            <wiskInput inputType="checkbox" :modelValue="selected" @update:modelValue="$emit('toggleBatchState', $event)"
              class="chk-draft-invoice-line-select" inputClass="bg-white" :class="{ forceVisible: selected }" />

            <b-dropdown left menuClass="mt- " :disabled="localDisabled" size="sm" variant="link" toggle-class="text-decoration-none pt-0 d-block" class="ms-1 bt-draft-invoice-line-more"
              no-caret>
              <template v-slot:button-content>
                <icon name="wisk-more-info" :scale="0.9" class="text-secondary" />
              </template>

              <b-dropdown-item @click="insertLine('before')">
                {{ translations.txtScannedInvoicesLineInsertAbove }}
              </b-dropdown-item>
              <b-dropdown-item @click="insertLine('after')">
                {{ translations.txtScannedInvoicesLineInsertBelow }}
              </b-dropdown-item>
            </b-dropdown>

          </b-col>
          <b-col md="4" cols="12" class="d-flex">
            <div class="text-start">
              <scannedInvoiceItem v-if="lineItem && lineItem.item_distributor_id" @selectedItem="onItemSelect" :lineItem="lineItem" />
              <span v-else class="text-danger">{{ translations.txtScannedInvoicesItemMissing }} - {{ localLineItem.title || localLineItem.original_title }} </span>
              <span v-if="localLineItem.item_measurement && !lineItem.item_distributor_id" :class="{ 'text-danger': !lineItem.item_distributor_id }">
                ({{ localLineItem.item_measurement.quantity }} {{ localLineItem.item_measurement.unit_of_measurement || localLineItem.item_measurement.type }})
              </span>
            </div>
          </b-col>
          <b-col md="2" cols="12" class="text-start">
            {{ translations.txtGenericCode }}: {{ localLineItem.item_code }}
          </b-col>
          <b-col md="3" cols="12" class="text-end">
            <span>
              <span v-if="localLineItem.measurement" :class="{ 'text-danger text-bold': !localLineItem.measurement.quantity }">
                {{ formatNumber(localLineItem.measurement.quantity, { decimals: 1, defaultIfEmpty: 0, decimalsAsNeeded: true }) }} {{ localLineItem.measurement.unit_of_measurement || localLineItem.measurement.type }}
              </span>
              <span v-if="!localLineItem.measurement"> - </span>
              &nbsp; | &nbsp;
              <priceEditor disabled :item="selectedItem" :caseSize="(selectedItem && selectedItem.case_size) || 1" :modelValue="localLineItem.price" discountAvailable showPlainText />
            </span>
          </b-col>
          <b-col md="2" cols="12" class="text-end">
            <span class="" :class="localLineItem.total ? 'text-dark' : 'text-danger'"> {{ translations.txtGenericTotal }}: {{ currencyFormat(localLineItem.total) }} </span>
          </b-col>

          <b-col cols="1" class="pe-4">
            <reviewMessages v-if="localLineItem.needs_review || localLineItem.needs_response" disabled :lineItem="localLineItem" iconOnly :key="reviewMessagesKey"/>
          </b-col>
          <b-col cols="11" v-if="localLineItem.gl_account_id || localLineItem.tax_rate_id || localLineItem.accounting_venue_class_id">
            <span v-if="localLineItem.gl_account_id" class="text-small-info text-gray-500 me-3">
              {{ glAccountsById[localLineItem.gl_account_id]?.title }}
            </span>
            <span v-if="localLineItem.tax_rate_id" class="text-small-info text-gray-500 me-3">
              {{ taxesById[localLineItem.tax_rate_id]?.title }}
            </span>
            <span v-if="localLineItem.accounting_venue_class_id" class="text-small-info text-gray-500 me-3">
              {{ accountingVenueClassesById[localLineItem.accounting_venue_class_id]?.title }}
            </span>
          </b-col>
          <b-col cols="12">
            <priceCheck inline :item="selectedItem" :modelValue="localLineItem.price" :previousPrice="localLineItem.previous_cost_per_unit" :currentPrice="localLineItem.price_per_unit" :totalChange="localLineItem.total_change" />
          </b-col>
        </b-row>

      </b-container>
    </b-col>

    <confirm ref="confirmDialog" />
  </b-row>
</template>

<script>
import { markRaw } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'
import get from 'lodash.get'
import api from '@/api'
import { currencyFormat, formatNumber } from '@/modules/utils'
import priceEditor from '@/components/bottles/ItemPrice'
import priceCheck from '@/components/bottles/ItemPriceCheck'
import inventoryEntryInput from '@/components/inventories/InventoryEntryInput'
import scannedInvoiceItem from '@/components/draftInvoices/DraftInvoiceItem'
import draftInvoiceLineTimeline from '@/components/draftInvoices/DraftInvoiceLineTimeline'
import totalPreview from '@/components/draftInvoices/DraftInvoiceLineTotalPreview'
import reviewMessages from '@/components/draftInvoices/DraftInvoiceLineReviewMessages'

export default {
  name: 'ScannedInvoiceLine',
  emits: ['locateLine', 'select', 'toggleBatchState', 'insertLine', 'selectNextLine', 'needsResponse', 'checkReviewStatus'],
  components: { scannedInvoiceItem, priceEditor, inventoryEntryInput, priceCheck, totalPreview, draftInvoiceLineTimeline, reviewMessages },
  props: { lineItem: Object, disabled: Boolean, distributor: Object, scannedInvoiceId: Number, ocrCellsById: Object, expanded: Boolean, selected: Boolean },
  data() {
    return {
      key: 1,
      debug: 1,
      draftInvoice: null,
      localDisabled: false,
      initDone: false,
      initDoneTimeoutId: null,
      localExpanded: false,
      entryType: 'unit',
      selectedItem: null,
      operations: [],
      totalPreviewOperations: [],
      needsKeyRefresh: false,
      reviewMessagesKey: 1,
      localLineItem: {
        item_code: '',
        gl_account_id: null,
        item_measurement: {
          quantity: 1,
          type: 'unit',
          unit_of_measurement: 'unit'
        },
        measurement: {
          quantity: 1,
          type: 'unit',
          value: 1
        },
        price: {
          type: 'manual',
          value: 0,
          measurement: {
            quantity: 1,
            type: 'unit',
            unit_of_measurement: 'unit'
          }
        }
      },
      dataError: null
    }
  },

  computed: {
    ...mapState(['translations', 'categoriesById', 'familiesById', 'draftInvoicesById', 'bottlesById', 'itemVariantsById', 'itemVariants', 'currentPermissionsByType', 'categoriesById', 'familiesById', 'venue', 'accountingVenueClasses', 'glAccountsById', 'taxesById', 'accountingVenueClassesById']),
    ...mapGetters(['currency', 'activeGLAccounts', 'activeTaxes']),
    domIdExpandedContainer() {
      return `draft-invoice-line-expanded-${this.localLineItem.id}`
    },
    locateLineOCRCellIds() {
      if (this.localLineItem?.ocr_cells?.length) {
        let arr = this.localLineItem.ocr_cells.map(c => c.value).filter(c => c && this.ocrCellsById[c])

        return arr.length ? arr : null
      }
      return null
    },
    taxSelectorDisabled() {
      return this.draftInvoice?.taxes_scheme === 'no_taxes'
    },
    measurementTypes() {
      let arr = [{ id: 'unit', title: 'unit' }],
        item = this.selectedItem

      if (item?.case_size > 1) {
        arr.push({ id: 'case', title: 'case' })
      }
      if (item?.measurement?.type === 'weight' || item?.measurement?.type === 'volume') {
        arr.push({ id: 'manual', title: 'manual' })
      }

      return arr
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    addGLAccountConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                callbackItemInjected(glAccount)
              }
            }
          })
        },
        label: this.translations.txtGLAccountNew
      }
    },
    completionStatusClass() {
      return this.localLineItem.completionStatus.replace(/_/g, '-')
    }
  },
  methods: {
    formatNumber,
    ...mapActions(['setGlobalAction']),
    currencyFormat,
    locateLine() {
      this.$emit('locateLine', this.localLineItem.id)
    },
    removeLine() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.onChangeSingle({ type: 'delete' }, 'removeLine')
          },
          message: this.translations.txtScannedInvoiceLineRemoveConfirmMessage,
          title: this.translations.txtScannedInvoiceLineRemoveConfirmationTitle,
        })
      }
    },
    onItemSelect(selectedItem) {
      if (this.debug) {
        console.log('selectedItem', selectedItem)
      }

      this.selectedItem = selectedItem

      if (this.selectedItem) {
        if (get(this.selectedItem, 'measurement.unit_of_measurement') &&
          (get(this.localLineItem, 'item_measurement.unit_of_measurement') !== get(this.selectedItem, 'measurement.unit_of_measurement') ||
            get(this.localLineItem, 'item_measurement.quantity') !== get(this.selectedItem, 'measurement.quantity'))) {
          this.localLineItem.item_measurement = this.selectedItem.measurement
          this.setOperation({ type: 'item_measurement', value: this.selectedItem.measurement, from: this.lineItem.item_measurement }, 'onItemSelect')
        }

        if ((this.localLineItem.title || '') !== (this.selectedItem.title || '')) {
          this.localLineItem.title = this.selectedItem.title
          this.setOperation({ type: 'title', value: this.selectedItem.title, from: this.lineItem.title }, 'onItemSelect')
        }

        if ((this.localLineItem.item_code || '') !== (this.selectedItem.distributor_code || '')) {
          this.localLineItem.item_code = this.selectedItem.distributor_code
          this.setOperation({ type: 'item_code', value: this.selectedItem.distributor_code, from: this.lineItem.item_code }, 'onItemSelect')
        }

        if (this.initDone && (this.selectedItem.case_size || 0) !== (this.localLineItem.case_size || 0)) {
          this.localLineItem.case_size = this.selectedItem.case_size
          this.localLineItem.measurement = this.localLineItem.measurement || {}
          this.localLineItem.measurement.case_size = this.selectedItem.case_size
          this.setOperation({ type: 'case_size', value: this.selectedItem.case_size, from: this.lineItem.case_size }, 'onItemSelect')
          // console.log('measurement operation investigation', this.localLineItem.measurement, 'onItemSelect')
          this.setOperation({ type: 'measurement', value: { ...this.localLineItem.measurement, type: this.entryType }, from: this.lineItem.measurement }, 'onItemSelect')
        }

        if (!this.localLineItem.gl_account_id) {
          let category = this.categoriesById[selectedItem.category_id] || {},
            family = this.familiesById[category.family_id] || {},
            variant = selectedItem.variant || {},
            glAccountId = variant.gl_account_id || family.gl_account_id || selectedItem.distributor?.gl_account_id

          if (glAccountId) {
            this.setOperation({ type: 'gl_account_id', value: glAccountId, from: this.lineItem.gl_account_id }, 'onItemSelect')
          }
        }

        if (selectedItem.tax_rate_id && !this.localLineItem.tax_rate_id) {
          this.setOperation({ type: 'tax_rate_id', value: selectedItem.tax_rate_id, from: this.lineItem.tax_rate_id }, 'onItemSelect')
        }

        //select item
        if (selectedItem && selectedItem.item_distributor_id && !this.localLineItem.item_distributor_id) {
          this.onChangeSingle([...this.operations, { type: 'item_distributor_id', value: this.selectedItem.item_distributor_id, from: this.lineItem.item_distributor_id }], 'onItemSelect')

          setTimeout(() => {
            this.operations = []
            this.needsKeyRefresh = true
          }, 0)
        }

        //clear selected item
        if (selectedItem && !selectedItem.item_distributor_id && this.localLineItem.item_distributor_id) {
          this.localLineItem.item_distributor_id = 0

          this.onChangeSingle({ type: 'item_distributor_id', value: 0, from: this.lineItem.item_distributor_id }, 'onItemSelect')
        }

        setTimeout(() => {
          this.$refs?.measurementTypeSelect?.triggerValidationCheck()
        }, 300)

        if (this.needsKeyRefresh) {
          this.key++
          this.needsKeyRefresh = false
        }
      }
    },
    setTotalPreviewOperation(operation) {
      if (!this.dataError) {
        let copy = this.totalPreviewOperations.filter(o => o.type !== operation.type).map(o => ({ ...o }))
        copy.push(markRaw(operation))
        this.totalPreviewOperations = copy
      }
    },
    onMeasurementOperationChange(operation) {
      if (operation.type === 'measurement' && operation.value.type === 'manual' && !operation.value.unit_of_measurement) {
        // unit_of_measurement gets replaced by other inputs
        // this will refill the value based on the item to prevent the invalid unit of measurement error
        const currentMeasurementOperation = this.operations.find(o => o.type === 'measurement')
        // console.log('measurement operation', operation, currentMeasurementOperation, caller)
        operation.value.unit_of_measurement = currentMeasurementOperation?.value?.unit_of_measurement || get(this.localLineItem, 'item_measurement.unit_of_measurement')
      }

      this.setOperation(operation, 'onMeasurementOperationChange')
    },
    setOperation(operation, caller) {
      if (this.debug) {
        console.log('setOperation caller', caller, operation)
      }

      if (operation?.type) {
        let found = this.operations.find(o => o.type === operation.type)

        if (found) {
          found.value = operation.value
        } else {
          this.operations.push(markRaw(operation))
        }

        this.operations = uniqWith(this.operations, isEqual)
      }
    },
    save() {
      this.onChangeSingle(this.operations, 'save')

      if (this.localLineItem.item_distributor_id) {
        this.$emit('selectNextLine', this.localLineItem.id)
      }
    },
    onChangeSingle(payload, caller) {
      if (this.debug) {
        console.log('onChangeSingle caller', caller, payload)
      }

      let operations = payload ? (Array.isArray(payload) ? payload : [payload]) : [...this.operations],
        isDelete = operations.find(o => o.type === 'delete'),
        isMarkDone = operations.find(o => o.type === 'needs_review' && !o.value),
        isNeedsResponse = operations.find(o => o.type === 'needs_response' && o.value),
        isNeedsReview = operations.find(o => o.type === 'needs_review' && o.value)

      if (payload) {
        let payloadTypes = operations.map(p => p.type)
        this.operations = this.operations.filter(o => !payloadTypes.includes(o.type))
      } else {
        this.operations = []
      }

      this.localDisabled = true

      if (isDelete) {
        this.$store.commit('mutateDraftInvoiceLine', { ...this.localLineItem, REMOVE: true })
      }

      api.updateDraftInvoiceLine(this.scannedInvoiceId, this.localLineItem.id, operations).then(updated => {
        if (!isDelete) {
          this.$store.commit('mutateDraftInvoiceLine', updated)
          this.localLineItem = { ...this.localLineItem, ...updated }
        }
        if (isMarkDone || isNeedsReview) {
          this.$emit('checkReviewStatus', updated)
        }
        if (isNeedsResponse) {
          this.$emit('needsResponse')
        }

        this.localDisabled = false
      })
    },
    onMeasurementTypeChange(entryType) {
      if (this.debug) {
        console.log('onMeasurementTypeChange', entryType)
      }
      if (entryType === 'manual') {
        const payload = {
          type: 'measurement',
          value: {
            quantity: 0,
            unit_of_measurement: get(this.localLineItem, 'item_measurement.unit_of_measurement'),
            type: entryType,
            case_size: this.localLineItem.case_size
          },
          from: this.localLineItem.measurement
        }
        this.setOperation(
          payload,
          'onMeasurementTypeChange ' + entryType
        )
        this.setTotalPreviewOperation(payload)
      } else {
        const payload = {
          type: 'measurement',
          value: { ...this.localLineItem.measurement, type: entryType, case_size: this.localLineItem.case_size },
          from: this.localLineItem.measurement
        }
        this.setOperation(
          payload,
          'onMeasurementTypeChange ' + entryType
        )
        this.setTotalPreviewOperation(payload)
      }
    },
    insertLine(position) {
      this.$emit('insertLine', { position, lineId: this.localLineItem.id })
    },
    checkEntryType(caller) {
      if (this.debug) {
        console.log('checkEntryType caller', caller, this.entryType)
        console.log('this.localLineItem', this.localLineItem)
        console.log('this.measurementTypes', this.measurementTypes)
        console.log('this.lineItem', JSON.parse(JSON.stringify(this.lineItem)))
      }
      let fromPrice = this.lineItem.price,
        fromMeasurement = this.lineItem.measurement

      this.dataError = ''
      document.querySelector('#draft-invoice-item-case-size .form-control')?.classList?.remove('attention', 'outline-danger')


      if (this.entryType !== 'manual' && this.measurementTypes?.length > 1 && !this.measurementTypes.find(m => m.id === this.entryType) && this.measurementTypes[0]?.id) {
        this.entryType = this.measurementTypes[0].id
        this.localLineItem.measurement.type = this.measurementTypes[0].id

        if (this.localLineItem.price && this.localLineItem.price.type !== 'manual' && !this.measurementTypes.find(m => m.id === this.localLineItem.price.type)) {
          this.localLineItem.price = { ...this.localLineItem.price, type: this.measurementTypes[0].id }
        }

        if (this.localLineItem.price.type !== fromPrice.type) {
          this.setOperation({ type: 'price', value: this.localLineItem.price, from: fromPrice }, 'checkEntryType - ' + caller)
        }
        if (this.localLineItem.measurement.type !== fromMeasurement.type) {
          this.setOperation({ type: 'measurement', value: this.localLineItem.measurement, from: fromMeasurement }, 'checkEntryType - ' + caller)
        }
      } else if (!this.measurementTypes.find(m => m.id === this.entryType)) {
        this.dataError = 'Invalid entry type'

        if (this.entryType === 'case') {
          this.dataError = 'Case size is not set'
          document.querySelector('#draft-invoice-item-case-size .form-control')?.classList?.add('attention', 'outline-danger')
        }
      }
    }
  },
  created() {
    if (this.scannedInvoiceId && this.draftInvoicesById && this.draftInvoicesById[this.scannedInvoiceId]) {
      this.draftInvoice = this.draftInvoicesById[this.scannedInvoiceId]
    }
  },
  mounted() {
    this.initDoneTimeoutId = setTimeout(() => {
      this.initDone = true
    }, 5000)
  },
  beforeUnmount() {
    clearTimeout(this.initDoneTimeoutId)
  },
  watch: {
    expanded: {
      immediate: true,
      handler() {
        this.localExpanded = !!this.expanded

        if (this.localExpanded) {
          this.localLineItem = merge({}, this.localLineItem, this.lineItem)
        } else {
          this.localLineItem = merge({}, this.lineItem)
        }
      }
    },
    measurementTypes() {
      this.checkEntryType('measurementTypes')
    },
    entryType() {
      this.checkEntryType('entryType watcher')
    },
    lineItem: {
      immediate: true,
      handler() {
        if (this.debug) {
          console.log('this.measurementTypes', this.measurementTypes)
        }
        console.log('this.lineItem', this.lineItem)
        if (!this.initDone) {
          this.localLineItem = merge({}, this.localLineItem, this.lineItem)
        }
        this.localLineItem.accounting_venue_class_id = this.lineItem.accounting_venue_class_id || this.venue.accounting_integration?.default_venue_class_id
        this.localLineItem.completionStatus = this.lineItem.completionStatus
        this.localLineItem.review_conversation = this.lineItem.review_conversation
        this.entryType = this.lineItem.measurement.type
      }
    },
    'lineItem.review_conversation': {
      deep: true,
      handler() {
        // this.reviewMessagesKey++
        console.log('this.reviewMessagesKey', this.reviewMessagesKey)
      }
    },
    disabled: {
      immediate: true,
      handler(disabled) {
        this.localDisabled = !!disabled
      }
    }
  }
}
</script>

<style lang="scss">
.scanned-invoice-line-row {
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0px 0px 8px var(--gray-200);
  box-sizing: border-box;
  border-left: 5px solid transparent;

  .chk-draft-invoice-line-select {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    max-width: 16px;
    max-height: 16px;

    // .wisk-input-checkbox-icon {
    //   box-shadow: 0px 0px 0px 2px white, 0px 4px 6px rgba(0, 0, 0, 0.1);
    // }

    &.forceVisible {
      visibility: visible;
    }
  }

  &.expanded {
    .chk-draft-invoice-line-select {
      top: -1px;
      left: 11px;
    }
  }

  &.collapsed {
    .chk-draft-invoice-line-select {
      top: -1px;
      left: 0;
    }
  }

  .bt-delete-scanned-invoice-line {
    visibility: hidden;
    position: absolute;
    top: -2px;
    right: -4px;
  }

  .bt-needs-attention-edit-notes {
    visibility: hidden;
  }

  .bt-needs-attention {
    visibility: hidden;

    &.active {
      visibility: visible;
    }
  }

  .bt-copy-title {
    visibility: hidden;
  }

  .drag-sort-scanned-invoice-line {
    visibility: hidden;
    position: absolute;
    top: -4px;
    // left: -5px;
    left: 5px
  }

  .locate-line-scanned-invoice-line {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: -4px;
    left: 30px;
  }

  &.needs-attention {
    border-left-color: var(--red-200);
  }

  &.completed {
    border-left-color: var(--green-100);
  }

  &.almost-completed {
    border-left-color: var(--yellow-200);
  }

  &:hover {

    .bt-copy-title,
    .chk-draft-invoice-line-select,
    .bt-delete-scanned-invoice-line,
    .drag-sort-scanned-invoice-line,
    .bt-needs-attention,
    .bt-needs-attention-edit-notes,
    .bt-edit-scanned-invoice-wisk-item,
    .locate-line-scanned-invoice-line,
    .bt-draft-invoice-line-more {
      visibility: visible;
    }

    &.needs-attention {
      border-left-color: var(--red-300);
    }

    &.completed {
      border-left-color: var(--green-200);
    }
  }

  .bt-draft-invoice-line-more {
    visibility: hidden;
  }

  .invoice-flexible-line {
    width: calc((100% - 1rem) / var(--inner-items, 5) * var(--inner-item-size, 1));
    flex-basis: calc((100% - 1rem) / var(--inner-items, 5) * var(--inner-item-size, 1));
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.hide-drag-handle {
  .drag-sort-scanned-invoice-line {
    display: none;
  }
}
</style>
